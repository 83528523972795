var render = function render(){var _vm=this,_c=_vm._self._c;return _c('c-box',{staticStyle:{"position":"relative"},attrs:{"bg":"vc-white.20"}},[_c('c-flex',{staticClass:"header",attrs:{"align":"['start','start', 'space-between']","flexDirection":"'column', 'column', 'row'","justify":"space-between","mt":"4","mb":"4"}},[_c('c-flex',{attrs:{"pt":"10px","justify":"['space-between','start', 'space-between']","align":"center"}},[_c('c-heading',{attrs:{"pt":"2","fontSize":"2xl","fontWeight":"700","mr":"3","ml":"3","mb":['2', '2', '0']}},[_vm._v(" Events ")])],1),_c('c-flex',{attrs:{"align":"center","justify":"['start','end', 'space-between']"}},[_c('search',{on:{"hide-sidebar":_vm.handleHideSidebar}}),_c('HeaderOptions')],1)],1),_c('c-flex',{attrs:{"flexDirection":['column', 'column', 'row']}},[_c('c-flex',{attrs:{"h":"100%","flex":_vm.hideSidebar ? '1' : '3'}},[_c('div',{staticStyle:{"width":"100%"}},[(_vm.isFetchingEvents)?_c('c-flex',{attrs:{"w":"800px","h":"500px","justify":"center","align":"center"}},[_c('c-spinner',{attrs:{"size":"xl","color":"orange.500","thickness":"2px"}})],1):_c('div',_vm._l((_vm.events),function(event){return _c('div',{key:event.id,class:{
              past_event: _vm.isPastEvent(event.end_date),
              event_div: _vm.isPastEvent(event.end_date),
            }},[_c('div',{staticClass:"event",staticStyle:{"position":"relative"},on:{"click":function($event){return _vm.$router.push({
                  name: 'EventDetails',
                  params: { id: event.id },
                })}}},[(_vm.isPastEvent(event.end_date))?_c('c-flex',{staticStyle:{"position":"absolute","right":"-2px","top":"-20px"},attrs:{"align":"center","mt":"2"}},[_c('c-icon',{attrs:{"size":"12px","name":"info"}}),_c('c-text',{attrs:{"ml":"2","fontSize":"11px"}},[_vm._v("Event ended")])],1):_vm._e(),_c('c-image',{attrs:{"h":"140px","w":"220px","src":event.thumb_nail ??
                  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRFo4PR9dPR7LmV1CqeEB6F5Z3jikCg8xBBA&usqp=CAU',"alt":"event thumbnail"}}),_c('div',{staticClass:"event_info"},[_c('h2',{staticClass:"event_title"},[_vm._v(_vm._s(event.name))]),_c('small',{staticClass:"date"},[_vm._v(" "+_vm._s(event.start_date)+", "+_vm._s(event.start_time)+_vm._s(event.time_zone)+" ")]),_c('c-flex',[_c('c-flex',{attrs:{"justify":"start","align":"center"}},[(event.type === 'VIRTUAL')?_c('c-icon',{attrs:{"size":"14px","name":"video"}}):_c('c-icon',{attrs:{"size":"14px","name":"users"}}),_c('c-text',{attrs:{"ml":"2","fontSize":"14px","fontWeight":"600"}},[_vm._v(_vm._s(event.type === 'VIRTUAL' ? 'Online' : 'In person'))])],1),_vm._v(" "+_vm._s(event.company)+" ")],1),_c('p',{staticClass:"descr"},[_vm._v(" "+_vm._s(event.description)+" ")]),_c('c-flex',{attrs:{"justify":"start","align":"center"}},[_c('c-icon',{attrs:{"name":"user-friends"}}),_c('p',{staticClass:"company ml-3"},[_vm._v(" "+_vm._s(event.attendees_aggregate.aggregate.count)+" "+_vm._s(event.attendees_aggregate.aggregate.count === 1 ? 'Attendee' : 'Attendees')+" ")])],1)],1)],1)])}),0),_c('infinite-loading',{on:{"infinite":_vm.getMoreEvents}},[_c('span',{attrs:{"slot":"no-more"},slot:"no-more"},[_c('div',{staticStyle:{"color":"rgba(255, 69, 0, 0.45)"}},[_vm._v("No more events")])]),_c('span',{attrs:{"slot":"no-results"},slot:"no-results"},[_c('div',{staticStyle:{"color":"rgba(255, 69, 0, 0.45)"}},[_vm._v("No events yet")])])])],1)]),(!_vm.hideSidebar)?_c('c-flex',{attrs:{"ml":"5","flex":_vm.hideSidebar ? '0' : '3'}},[_c('sidebar')],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }