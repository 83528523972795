import { render, staticRenderFns } from "./NotificationDialog.vue?vue&type=template&id=0dc72c4b&scoped=true"
import script from "./NotificationDialog.vue?vue&type=script&lang=js"
export * from "./NotificationDialog.vue?vue&type=script&lang=js"
import style0 from "./NotificationDialog.vue?vue&type=style&index=0&id=0dc72c4b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0dc72c4b",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CLink: require('@chakra-ui/vue').CLink, CDrawerOverlay: require('@chakra-ui/vue').CDrawerOverlay, CDrawerCloseButton: require('@chakra-ui/vue').CDrawerCloseButton, CDrawerHeader: require('@chakra-ui/vue').CDrawerHeader, CButton: require('@chakra-ui/vue').CButton, CFlex: require('@chakra-ui/vue').CFlex, CText: require('@chakra-ui/vue').CText, CDrawerBody: require('@chakra-ui/vue').CDrawerBody, CDrawerContent: require('@chakra-ui/vue').CDrawerContent, CDrawer: require('@chakra-ui/vue').CDrawer})
