<template>
  <c-box>
    <c-modal :is-open="isOpen" :on-close="onClose" :closeOnOverlayClick="false">
      <c-modal-content ref="content" borderRadius="8px">
        <c-modal-header color="vc-orange.400"
          >Remove Team member</c-modal-header
        >
        <c-modal-close-button />
        <c-modal-body pb="6">
          <c-text mb="4" fontSize="lg" fontWeight="500">
            To remove team member type their email address into the field below
          </c-text>
          <c-stack :spacing="6">
            <form @submit.prevent="onDeleteFormSubmit">
              <c-stack :spacing="5">
                <c-input
                  type="email"
                  placeholder="Email Address"
                  v-model="$v.deleteForm.email.$model"
                  @paste.prevent
                />
                <c-flex justify="flex-end">
                  <c-button
                    type="submit"
                    :disabled="$v.deleteForm.$invalid || isDeletingMember"
                    variant-color="vc-orange"
                  >
                    <c-spinner
                      v-if="isDeletingMember"
                      color="#fff"
                      thickness="3px"
                    />
                    <c-text color="#fff" v-else> Remove </c-text>
                  </c-button>
                </c-flex>
              </c-stack>
            </form>
          </c-stack>
        </c-modal-body>
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>
  </c-box>
</template>

<script>
import { deleteTeamMember } from '@/services/company';
import { required, email, sameAs } from 'vuelidate/lib/validators';

export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    memberToDelete: {
      type: Object,
      default: () => ({}),
    },
    onClose: {
      type: Function,
    },
  },
  data() {
    return {
      deleteForm: {
        email: '',
        userEmail: '',
      },
      isDeletingMember: false,
    };
  },
  watch: {
    isOpen() {
      if (this.isOpen) {
        this.$v.deleteForm.userEmail.$model = this.memberToDelete.user.email;
      } else {
        this.$v.deleteForm.$model = {
          email: '',
          userEmail: '',
        };
      }
    },
  },
  validations: {
    deleteForm: {
      email: {
        email,
        required,
        sameAsUserEmail: sameAs('userEmail'),
      },
      userEmail: {
        email,
      },
    },
  },
  methods: {
    async onDeleteFormSubmit() {
      this.isDeletingMember = true;
      try {
        await deleteTeamMember(this.memberToDelete.id);
        this.$toast({
          title: 'Success!!!',
          description: `User removed from team successfully`,
          status: 'success',
          position: 'top',
          duration: 3000,
        });
        this.onClose();
        this.isDeletingMember = false;
      } catch (e) {
        this.isDeletingMember = false;
      }
    },
  },
};
</script>

<style></style>
